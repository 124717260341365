import { Outlet } from "react-router-dom";

const EmployeeLayout = () => {
    return (
        <>
            <Outlet />
        </>)
};

export default EmployeeLayout;
