const ROLES = Object.freeze({
  SUPER_ADMIN: "SUPER_ADMIN",
  ORG_ADMIN: "ORGANIZATION_ADMIN",
  MANAGER: "MANAGER",
  HR: "HUMAN_RESOURCE",
  EMPLOYEE: "EMPLOYEE",
  PAYROLL: "PAYROLL",
});

export default ROLES;
