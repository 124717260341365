// import login from "../auth/pages/login";
import signup from "../OrgAdmin/CompleteSignup";
import signin from "../auth/shared/signin";
import verifyuser from "../OrgAdmin/verifyuser";
import RegisterOrganization from "../OrgAdmin/orgRegister";
import OADashBoard from "../Dashboard/OADashboard/OADashBoard";
import OADashBoardNext from "../Dashboard/OADashboard/OADashBoardNext";
import ForgotPassword from "../auth/shared/forgot-password";
import ResetPassword from "../auth/shared/reset-password";
import ROLES from "../constants/roles";
// import verify from "../auth/pages/verify"
import Departments from "../Departments/Departments";
import Disciplinary from "../Disciplinary/Disciplinary";
import SAOrganization from "../SuperAdmin/SAOrganization";
import SAUserList from "../SuperAdmin/SAUserList";
import Benefits from "../Employee/AddEmployee/Benefits";
import CertificatesInfo from "../Employee/AddEmployee/CertificatesInfo";
import JobDetails from "../Employee/AddEmployee/JobDetails";
import PersonalInfo from "../Employee/AddEmployee/PersonalInfo";
import EmployeeList from "../Employee/ViewEmployee/EmployeeList";
import EmployeeJobDetails from "../Employee/ViewEmployee/EmployeeJobDetails";
import EmployeePersonal from "../Employee/ViewEmployee/EmployeePersonal";
import EVBenefits from "../Employee/ViewEmployee/EVBenefits";
import EVCertificates from "../Employee/ViewEmployee/EVCertificates";
import EVDiscipline from "../Employee/ViewEmployee/EVDiscipline";
import EVDocuments from "../Employee/ViewEmployee/EVDocuments";
import EVLeaveHistory from "../Employee/ViewEmployee/EVLeaveHistory";
import EVPerformance from "../Employee/ViewEmployee/EVPerformance";
import SharedSignin from "../Shared/Auth/Signin";
import VerifyOTP from "../Shared/Auth/VerifyOTP";
import SALayout from "../SuperAdmin/SALayout";
import CompleteSignup from "../OrgAdmin/CompleteSignup";
import OADashBoardLayout from "../Dashboard/OADashboard/OADashBoardLayout";
import EmployeeDetailLayout from "../Employee/ViewEmployee/EmployeeDetailLayout";
import AddEmployeeLayout from "../Employee/AddEmployee/AddEmployeeLayout";
import EmployeeLayout from "../Employee/EmployeeLayout";
import OABenefits from "../Benefits/OABenefits";
import EmployeeTypes from "../Employee/EmployeeType/EmployeeTypes";
import OALeaves from "../Leaves/OaLeaves/OALeaves";
import ManagerLeaves from "../Leaves/ManagerLeaves/ManagerLeaves";
import ManagerSideBar from "../Dashboard/ManagerDashboard/ManagerSideBar";
import ManagerLayout from "../Dashboard/ManagerDashboard/ManagerLayout";
import EvLeaveAlloacation from "../Employee/ViewEmployee/EvLeaveAlloacation";
import ManagerDashBoard from "../Dashboard/ManagerDashboard/ManagerDashBoard";
import ManagerEmployeeLayout from "../Employee/ManagerEmployee/ManagerEmployeeLayout";
import ManagerAccountLayout from "../Account/ManagerAccountLayout";
import ManagerLeaveAction from "../Leaves/ManagerLeaves/ManagerLeaveAction";
import HRLayout from "../Dashboard/HRDashboard/HRLayout";
import HREmployeeLayout from "../Employee/HREmployee/HREmployeeLayout";
import HRAccountLayout from "../Account/HRAccountLayout";
import UserLayout from "../Dashboard/UserDashboard/UserLayout";
import UserAccountLayout from "../Account/UserAccountLayout";
import Task from "../Task/Task";
import TaskView from "../Task/TaskView";
import Documents from "../Documents/Documents";
import DocumentsTags from "../Documents/DocumentsTags";
import DocumentHistory from "../Documents/DocumentHistory";
import LeaveHistory from "../Leaves/LeaveHistory";
import JobPosting from "../Staffing/JobPosting";
import JobView from "../Staffing/JobView";
import JobDetailsLayout from "../Staffing/JobDetailsLayout";
import Applicants from "../Staffing/Applicants";
import Offboarding from "../OffBoarding/offBoarding";
import Announcements from "../Announcements/Announcements";
import AnnouncementDetails from "../Announcements/AnnouncementDetails";
import SendWelcome from "../Employee/SendWelcome";
import Reports from "../Reports/Reports";
import EvChart from "../Employee/ViewEmployee/EvChart";
import PayRollLayout from "../Dashboard/PayRollDashBoard/PayRollLayout";
import PayRollEmployeeLayout from "../Employee/PayRollEmployee/PayRollEmployeeLayout";
import PayRollAccountLayout from "../Account/PayRollAccountLayout";
import EVRecognition from "../Employee/ViewEmployee/EvRecognition";
const Routes: any = [
  {
    component: signup,
    path: "/complete-signup",
    title: "Org Admin Register",
    to: "/",
    type: "public",
    children: [],
  },

  {
    component: ForgotPassword,
    path: "/forgot-password",
    title: "forgot-password",
    to: "/",
    type: "public",
    children: [],
  },

  {
    component: ResetPassword,
    path: "/reset-password/:token",
    title: "reset-password",
    to: "/",
    type: "public",
    children: [],
  },
  {
    component: verifyuser,
    path: "/verifyuser",
    title: "verifyuser",
    to: "/",
    type: "public",
    children: [],
  },
  {
    component: RegisterOrganization,
    path: "/register-organization",
    title: "register-organization",
    to: "/",
    type: "private",
    meta: { allowedRoles: [ROLES.ORG_ADMIN] },
    children: [],
  },
  {
    component: OADashBoardLayout,
    path: "/organization-admin",
    title: "OrganizationAdmin",
    to: "/",
    meta: { allowedRoles: [ROLES.ORG_ADMIN] },
    type: "private",
    children: [
      {
        component: OADashBoard,
        path: "dashboard",
        title: "DashBoard",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.ORG_ADMIN] },
        index: true,
      },
      {
        component: OABenefits,
        path: "benefits",
        title: "Benefits",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.ORG_ADMIN] },
        index: true,
      },
      {
        component: OALeaves,
        path: "leaves",
        title: "Leaves",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.ORG_ADMIN] },
      },
      {
        component: Announcements,
        path: "announcements",
        title: "Announcements",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.ORG_ADMIN] },
      },
      {
        component: AnnouncementDetails,
        path: "announcements/details/:id",
        title: "Announcements",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.ORG_ADMIN] },
      },
      {
        component: EmployeeTypes,
        path: "employee-types",
        title: "Employee Types",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.ORG_ADMIN] },
      },
      {
        component: Departments,
        path: "departments",
        title: "Departments",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.ORG_ADMIN] },
      },

      {
        component: Offboarding,
        path: "offboarding",
        title: "Offboarding",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.ORG_ADMIN] },
      },

      // {
      //   component: Task,
      //   path: "tasks",
      //   title: "tasks",
      //   to: "/",
      //   type: "private",
      //   index: true,
      //   meta: { allowedRoles: [ROLES.ORG_ADMIN] },
      // },
      // {
      //   component: TaskView,
      //   path: "tasks/details/:taskid",
      //   title: "Tasks View",
      //   to: "/",
      //   type: "private",
      //   index: true,
      //   meta: { allowedRoles: [ROLES.ORG_ADMIN] },
      // },
      {
        component: Documents,
        path: "documents",
        title: "Tasks View",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.ORG_ADMIN] },
      },
      {
        component: DocumentHistory,
        path: "documents/history/:documentid",
        title: "Tasks View",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.ORG_ADMIN] },
      },

      {
        component: DocumentsTags,
        path: "document-tags",
        title: "Tasks View",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.ORG_ADMIN] },
      },
      {
        component: JobPosting,
        path: "job-posting",
        title: "tasks",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.ORG_ADMIN] },
      },
      {
        component: JobView,
        path: "job-posting/details/:jobid",
        title: "tasks",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.ORG_ADMIN] },
      },

      {
        component: Disciplinary,
        path: "disciplinary",
        title: "Disciplinary ",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.ORG_ADMIN] },
      },
      {
        component: SendWelcome,
        path: "employee/send-welcome",
        title: "Disciplinary ",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.ORG_ADMIN] },
      },
      {
        component: EmployeeLayout,
        path: "employee",
        title: "Employee ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.ORG_ADMIN] },
        children: [
          {
            component: EmployeeList,
            path: "list",
            title: "Employee ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.ORG_ADMIN] },
          },
          {
            component: EmployeeDetailLayout,
            path: "details",
            title: "Employee ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.ORG_ADMIN] },
            children: [
              {
                component: EmployeePersonal,
                path: "personal-info/:employeeid",
                title: "EmployeePersonal ",
                to: "/",
                type: "private",
                index: true,
                meta: { allowedRoles: [ROLES.ORG_ADMIN] },
              },

              {
                component: EmployeeJobDetails,
                path: "job-details/:employeeid",
                title: "EmployeeJobDetails ",
                to: "/",
                type: "private",
                index: true,
                meta: { allowedRoles: [ROLES.ORG_ADMIN] },
              },
              {
                component: EVBenefits,
                path: "benefits/:employeeid",
                title: "Benefits ",
                to: "/",
                type: "private",
                index: true,
                meta: { allowedRoles: [ROLES.ORG_ADMIN] },
              },

              {
                component: EVLeaveHistory,
                path: "leave-history/:employeeid",
                title: "EVLeaveHistory ",
                to: "/",
                type: "private",
                meta: { allowedRoles: [ROLES.ORG_ADMIN] },
              },
              {
                component: EVCertificates,
                path: "certificates/:employeeid",
                title: "EVCertificates ",
                to: "/",
                type: "private",
                meta: { allowedRoles: [ROLES.ORG_ADMIN] },
              },
              {
                component: EvChart,
                path: "chart/:employeeid",
                title: "EvChart ",
                to: "/",
                type: "private",
                meta: { allowedRoles: [ROLES.ORG_ADMIN] },
              },
              {
                component: EVDiscipline,
                path: "discipline/:employeeid",
                title: "EVDiscipline ",
                to: "/",
                type: "private",
                meta: { allowedRoles: [ROLES.ORG_ADMIN] },
              },
              {
                component: EvLeaveAlloacation,
                path: "leave-alloacation/:employeeid",
                title: "Leave Alloaction",
                to: "/",
                meta: { allowedRoles: [ROLES.ORG_ADMIN] },
                type: "private",
              },
              {
                component: EVRecognition,
                path: "recognition/:employeeid",
                title: "EVRecognition ",
                to: "/",
                type: "private",
                meta: { allowedRoles: [ROLES.ORG_ADMIN] },
              },
              {
                component: EVDocuments,
                path: "documents/:employeeid",
                title: "EVDocuments ",
                to: "/",
                type: "private",
                meta: { allowedRoles: [ROLES.ORG_ADMIN] },
              },
              {
                component: EVPerformance,
                path: "performance/:employeeid",
                title: "EVPerformance ",
                to: "/",
                type: "private",
                meta: { allowedRoles: [ROLES.ORG_ADMIN] },
              },
            ],
          },
          {
            component: Benefits,
            path: "benefits/:employeeid/:edit?",
            title: "Benefits ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.ORG_ADMIN] },
          },
          {
            component: CertificatesInfo,
            path: "certificates-info/:employeeid/:edit?",
            title: "CertificatesInfo ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.ORG_ADMIN] },
          },
          {
            component: JobDetails,
            path: "job-details/:employeeid/:edit?",
            title: "JobDetails ",
            to: "/",
            meta: { allowedRoles: [ROLES.ORG_ADMIN] },
            type: "private",
          },
          {
            component: PersonalInfo,
            path: "personal-info/:employeeid/:edit?",
            title: "PersonalInfo ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.ORG_ADMIN] },
          },
        ],
      },
    ],
  },

  {
    component: SharedSignin,
    path: "/",
    title: "Signin",
    to: "/",
    type: "public",
    children: [],
  },
  {
    component: VerifyOTP,
    path: "/verify-otp",
    title: "Verify OTP",
    to: "/",
    type: "public",
    children: [],
  },
  {
    component: CompleteSignup,
    path: "/organization-admin/complete-signup/:email/:token",
    title: "Organization Admin | Signup",
    to: "/",
    type: "public",
    children: [],
  },

  {
    component: SALayout,
    path: "/super-admin",
    title: "SuperAdmin",
    to: "/",
    type: "private",
    meta: { allowedRoles: [ROLES.SUPER_ADMIN] },
    children: [
      {
        component: SAOrganization,
        path: "organizations",
        title: "Organizations",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.SUPER_ADMIN] },
        index: true,
      },
      {
        component: OABenefits,
        path: "benefits",
        title: "Benefits",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.SUPER_ADMIN] },
        index: true,
      },
      {
        component: EmployeeTypes,
        path: "employee-types",
        title: "Employee Types",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.SUPER_ADMIN] },
      },
      {
        component: Departments,
        path: "departments",
        title: "Departments",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.SUPER_ADMIN] },
      },
      {
        component: DocumentsTags,
        path: "document-tags",
        title: "Tasks View",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.SUPER_ADMIN] },
      },
      {
        component: OALeaves,
        path: "leaves",
        title: "Leaves",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.SUPER_ADMIN] },
      },
      {
        component: Disciplinary,
        path: "disciplinary",
        title: "Disciplinary ",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.SUPER_ADMIN] },
      },
    ],
  },
  {
    component: ManagerLayout,
    path: "/manager-management",
    title: "Manager",
    to: "/",
    meta: { allowedRoles: [ROLES.MANAGER] },
    type: "private",
    children: [
      {
        component: ManagerDashBoard,
        path: "dashboard",
        title: "Manager DashBoard",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.MANAGER] },
        index: true,
      },
      {
        component: ManagerLeaves,
        path: "leaves",
        title: "Leaves",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.MANAGER] },
      },
      {
        component: ManagerLeaveAction,
        path: "request/:employeeid/:requestid",
        title: "Leaves",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.MANAGER] },
      },
      // {
      //   component: Task,
      //   path: "tasks",
      //   title: "tasks",
      //   to: "/",
      //   type: "private",
      //   index: true,
      //   meta: { allowedRoles: [ROLES.MANAGER] },
      // },
      {
        component: LeaveHistory,
        path: "leave/history",
        title: "tasks",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.MANAGER] },
      },

      // {
      //   component: TaskView,
      //   path: "tasks/details/:taskid",
      //   title: "Tasks View",
      //   to: "/",
      //   type: "private",
      //   index: true,
      //   meta: { allowedRoles: [ROLES.MANAGER] },
      // },
      {
        component: Announcements,
        path: "announcements",
        title: "Announcements",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.MANAGER] },
      },
      {
        component: AnnouncementDetails,
        path: "announcements/details/:id",
        title: "Announcements",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.MANAGER] },
      },
      {
        component: Documents,
        path: "documents",
        title: "Tasks View",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.MANAGER] },
      },
      {
        component: DocumentHistory,
        path: "documents/history/:documentid",
        title: "Tasks View",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.MANAGER] },
      },
      {
        component: JobPosting,
        path: "job-posting",
        title: "tasks",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.MANAGER] },
      },
      {
        component: JobView,
        path: "job-posting/details/:jobid",
        title: "tasks",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.MANAGER] },
      },
      {
        component: EmployeeList,
        path: "employee-list",
        title: "Employee List",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.MANAGER] },
      },
      {
        component: ManagerEmployeeLayout,
        path: "employee-details",
        title: "Employee ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.MANAGER] },
        children: [
          {
            component: EmployeePersonal,
            path: "personal-info/:employeeid",
            title: "EmployeePersonal ",
            to: "/",
            type: "private",
            index: true,
            meta: { allowedRoles: [ROLES.MANAGER] },
          },

          {
            component: EmployeeJobDetails,
            path: "job-details/:employeeid",
            title: "EmployeeJobDetails ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
          },
          {
            component: EVBenefits,
            path: "benefits/:employeeid",
            title: "Benefits ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
          },

          {
            component: EVLeaveHistory,
            path: "leave-history/:employeeid",
            title: "EVLeaveHistory ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
          },
          {
            component: EVCertificates,
            path: "certificates/:employeeid",
            title: "EVCertificates ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
          },
          {
            component: EvChart,
            path: "chart/:employeeid",
            title: "EvChart ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
          },
          {
            component: EVDiscipline,
            path: "discipline/:employeeid",
            title: "EVDiscipline ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
          },
          {
            component: EvLeaveAlloacation,
            path: "leave-alloacation/:employeeid",
            title: "Leave Alloaction",
            to: "/",
            meta: { allowedRoles: [ROLES.MANAGER] },
            type: "private",
          },
          {
            component: EVRecognition,
            path: "recognition/:employeeid",
            title: "EVRecognition ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
          },
          {
            component: EVDocuments,
            path: "documents/:employeeid",
            title: "EVDocuments ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
          },
          {
            component: EVPerformance,
            path: "performance/:employeeid",
            title: "EVPerformance ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
          },
        ],
      },
      {
        component: Benefits,
        path: "benefits/:employeeid/:edit?",
        title: "Benefits ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.MANAGER] },
      },
      {
        component: CertificatesInfo,
        path: "certificates-info/:employeeid/:edit?",
        title: "CertificatesInfo ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.MANAGER] },
      },
      {
        component: JobDetails,
        path: "job-details/:employeeid/:edit?",
        title: "JobDetails ",
        to: "/",
        meta: { allowedRoles: [ROLES.MANAGER] },
        type: "private",
      },
      {
        component: PersonalInfo,
        path: "personal-info/:employeeid/:edit?",
        title: "PersonalInfo ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.MANAGER] },
      },
      {
        component: ManagerAccountLayout,
        path: "account",
        title: "Employee ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.MANAGER] },
        children: [
          {
            component: EmployeePersonal,
            path: "personal-info/:employeeid",
            title: "EmployeePersonal ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
            index: true,
          },

          {
            component: EmployeeJobDetails,
            path: "job-details/:employeeid",
            title: "EmployeeJobDetails ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
          },
          {
            component: EVBenefits,
            path: "benefits/:employeeid",
            title: "Benefits ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
          },

          {
            component: EVLeaveHistory,
            path: "leave-history/:employeeid",
            title: "EVLeaveHistory ",
            to: "/",
            meta: { allowedRoles: [ROLES.MANAGER] },
            type: "private",
          },
          {
            component: EVCertificates,
            path: "certificates/:employeeid",
            title: "EVCertificates ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
          },
          {
            component: EvChart,
            path: "chart/:employeeid",
            title: "EvChart ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
          },
          {
            component: EVDiscipline,
            path: "discipline/:employeeid",
            title: "EVDiscipline ",
            to: "/",
            meta: { allowedRoles: [ROLES.MANAGER] },
            type: "private",
          },
          {
            component: EvLeaveAlloacation,
            path: "leave-alloacation/:employeeid",
            title: "Leave Alloaction",
            to: "/",
            meta: { allowedRoles: [ROLES.MANAGER] },
            type: "private",
          },
          {
            component: EVRecognition,
            path: "recognition/:employeeid",
            title: "EVRecognition ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
          },
          {
            component: EVDocuments,
            path: "documents/:employeeid",
            title: "EVDocuments ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
          },
          {
            component: EVPerformance,
            path: "performance/:employeeid",
            title: "EVPerformance ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.MANAGER] },
          },
        ],
      },
    ],
  },
  {
    component: HRLayout,
    path: "/hr-management",
    title: "HUMAN_RESOURCE",
    to: "/",
    meta: { allowedRoles: [ROLES.HR] },

    type: "private",
    children: [
      {
        component: ManagerDashBoard,
        path: "dashboard",
        title: "Manager DashBoard",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.HR] },
      },
      {
        component: ManagerLeaves,
        path: "leaves",
        title: "Leaves",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.HR] },
      },
      {
        component: ManagerLeaveAction,
        path: "request/:employeeid/:requestid",
        title: "Leaves",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.HR] },
      },
      // {
      //   component: Task,
      //   path: "tasks",
      //   title: "tasks",
      //   to: "/",
      //   type: "private",
      //   index: true,
      //   meta: { allowedRoles: [ROLES.HR] },
      // },
      // {
      //   component: TaskView,
      //   path: "tasks/details/:taskid",
      //   title: "Tasks View",
      //   to: "/",
      //   type: "private",
      //   index: true,
      //   meta: { allowedRoles: [ROLES.HR] },
      // },
      {
        component: Reports,
        path: "reports",
        title: "Reports",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.HR] },
      },
      {
        component: Announcements,
        path: "announcements",
        title: "Announcements",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.HR] },
      },
      {
        component: AnnouncementDetails,
        path: "announcements/details/:id",
        title: "Announcements",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.HR] },
      },
      {
        component: LeaveHistory,
        path: "leave/history",
        title: "tasks",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.HR] },
      },
      {
        component: JobPosting,
        path: "job-posting",
        title: "tasks",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.HR] },
      },
      {
        component: JobView,
        path: "job-posting/details/:jobid",
        title: "tasks",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.HR] },
      },
      // {
      //   component: JobDetailsLayout,
      //   path: "job-posting/details",
      //   title: "HUMAN_RESOURCE",
      //   to: "/",
      //   type: "private",
      //   children: [
      //     {
      //       component: JobView,
      //       path: "job/:employeeId",
      //       title: "tasks",
      //       to: "/",
      //       type: "private",
      //       index: true,
      //     },
      //     {
      //       component: Applicants,
      //       path: "applicants/:employeeId",
      //       title: "tasks",
      //       to: "/",
      //       type: "private",
      //       index: true,
      //     },
      //   ],
      // },

      {
        component: Documents,
        path: "documents",
        title: "Tasks View",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.HR] },
      },
      {
        component: DocumentHistory,
        path: "documents/history/:documentid",
        title: "Tasks View",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.HR] },
      },
      {
        component: EmployeeList,
        path: "employee-list",
        title: "Employee List",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.HR] },
      },
      {
        component: HREmployeeLayout,
        path: "employee-details",
        title: "Employee ",
        to: "/",
        meta: { allowedRoles: [ROLES.HR] },
        type: "private",
        children: [
          {
            component: EmployeePersonal,
            path: "personal-info/:employeeid",
            title: "EmployeePersonal ",
            to: "/",
            type: "private",
            index: true,
            meta: { allowedRoles: [ROLES.HR] },
          },

          {
            component: EmployeeJobDetails,
            path: "job-details/:employeeid",
            title: "EmployeeJobDetails ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
          {
            component: EVBenefits,
            path: "benefits/:employeeid",
            title: "Benefits ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },

          {
            component: EVLeaveHistory,
            path: "leave-history/:employeeid",
            title: "EVLeaveHistory ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
          {
            component: EVCertificates,
            path: "certificates/:employeeid",
            title: "EVCertificates ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
          {
            component: EvChart,
            path: "chart/:employeeid",
            title: "EvChart ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
          {
            component: EVDiscipline,
            path: "discipline/:employeeid",
            title: "EVDiscipline ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
          {
            component: EvLeaveAlloacation,
            path: "leave-alloacation/:employeeid",
            title: "Leave Alloaction",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
          {
            component: EVRecognition,
            path: "recognition/:employeeid",
            title: "EVRecognition ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
          {
            component: EVDocuments,
            path: "documents/:employeeid",
            title: "EVDocuments ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
          {
            component: EVPerformance,
            path: "performance/:employeeid",
            title: "EVPerformance ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
        ],
      },
      {
        component: Benefits,
        path: "benefits/:employeeid/:edit?",
        title: "Benefits ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.HR] },
      },
      {
        component: CertificatesInfo,
        path: "certificates-info/:employeeid/:edit?",
        title: "CertificatesInfo ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.HR] },
      },
      {
        component: JobDetails,
        path: "job-details/:employeeid/:edit?",
        title: "JobDetails ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.HR] },
      },
      {
        component: PersonalInfo,
        path: "personal-info/:employeeid/:edit?",
        title: "PersonalInfo ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.HR] },
      },
      {
        component: HRAccountLayout,
        path: "account",
        title: "Employee ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.HR] },
        children: [
          {
            component: EmployeePersonal,
            path: "personal-info/:employeeid",
            title: "EmployeePersonal ",
            to: "/",
            type: "private",
            index: true,
            meta: { allowedRoles: [ROLES.HR] },
          },

          {
            component: EmployeeJobDetails,
            path: "job-details/:employeeid",
            title: "EmployeeJobDetails ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
          {
            component: EVBenefits,
            path: "benefits/:employeeid",
            title: "Benefits ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },

          {
            component: EVLeaveHistory,
            path: "leave-history/:employeeid",
            title: "EVLeaveHistory ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
          {
            component: EVCertificates,
            path: "certificates/:employeeid",
            title: "EVCertificates ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
          {
            component: EvChart,
            path: "chart/:employeeid",
            title: "EvChart ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
          {
            component: EVDiscipline,
            path: "discipline/:employeeid",
            title: "EVDiscipline ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
          {
            component: EvLeaveAlloacation,
            path: "leave-alloacation/:employeeid",
            title: "Leave Alloaction",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
          {
            component: EVRecognition,
            path: "recognition/:employeeid",
            title: "EVRecognition ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
          {
            component: EVDocuments,
            path: "documents/:employeeid",
            title: "EVDocuments ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
          {
            component: EVPerformance,
            path: "performance/:employeeid",
            title: "EVPerformance ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.HR] },
          },
        ],
      },
    ],
  },
  {
    component: PayRollLayout,
    path: "/payroll-management",
    title: "HUMAN_RESOURCE",
    to: "/",
    meta: { allowedRoles: [ROLES.PAYROLL] },

    type: "private",
    children: [
      {
        component: ManagerDashBoard,
        path: "dashboard",
        title: "Manager DashBoard",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.PAYROLL] },
      },
      {
        component: ManagerLeaves,
        path: "leaves",
        title: "Leaves",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.PAYROLL] },
      },
      {
        component: ManagerLeaveAction,
        path: "request/:employeeid/:requestid",
        title: "Leaves",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.PAYROLL] },
      },
      // {
      //   component: Task,
      //   path: "tasks",
      //   title: "tasks",
      //   to: "/",
      //   type: "private",
      //   index: true,
      //   meta: { allowedRoles: [ROLES.PAYROLL] },
      // },
      // {
      //   component: TaskView,
      //   path: "tasks/details/:taskid",
      //   title: "Tasks View",
      //   to: "/",
      //   type: "private",
      //   index: true,
      //   meta: { allowedRoles: [ROLES.PAYROLL] },
      // },
      {
        component: Reports,
        path: "reports",
        title: "Reports",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.PAYROLL] },
      },
      {
        component: Announcements,
        path: "announcements",
        title: "Announcements",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.PAYROLL] },
      },
      {
        component: AnnouncementDetails,
        path: "announcements/details/:id",
        title: "Announcements",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.PAYROLL] },
      },
      {
        component: LeaveHistory,
        path: "leave/history",
        title: "tasks",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.PAYROLL] },
      },
      {
        component: JobPosting,
        path: "job-posting",
        title: "tasks",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.PAYROLL] },
      },
      {
        component: JobView,
        path: "job-posting/details/:jobid",
        title: "tasks",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.PAYROLL] },
      },
      // {
      //   component: JobDetailsLayout,
      //   path: "job-posting/details",
      //   title: "HUMAN_RESOURCE",
      //   to: "/",
      //   type: "private",
      //   children: [
      //     {
      //       component: JobView,
      //       path: "job/:employeeId",
      //       title: "tasks",
      //       to: "/",
      //       type: "private",
      //       index: true,
      //     },
      //     {
      //       component: Applicants,
      //       path: "applicants/:employeeId",
      //       title: "tasks",
      //       to: "/",
      //       type: "private",
      //       index: true,
      //     },
      //   ],
      // },

      {
        component: Documents,
        path: "documents",
        title: "Tasks View",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.PAYROLL] },
      },
      {
        component: DocumentHistory,
        path: "documents/history/:documentid",
        title: "Tasks View",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.PAYROLL] },
      },
      {
        component: EmployeeList,
        path: "employee-list",
        title: "Employee List",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.PAYROLL] },
      },
      {
        component: PayRollEmployeeLayout,
        path: "employee-details",
        title: "Employee ",
        to: "/",
        meta: { allowedRoles: [ROLES.PAYROLL] },
        type: "private",
        children: [
          {
            component: EmployeePersonal,
            path: "personal-info/:employeeid",
            title: "EmployeePersonal ",
            to: "/",
            type: "private",
            index: true,
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },

          {
            component: EmployeeJobDetails,
            path: "job-details/:employeeid",
            title: "EmployeeJobDetails ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },
          {
            component: EVBenefits,
            path: "benefits/:employeeid",
            title: "Benefits ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },

          {
            component: EVLeaveHistory,
            path: "leave-history/:employeeid",
            title: "EVLeaveHistory ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },
          {
            component: EVCertificates,
            path: "certificates/:employeeid",
            title: "EVCertificates ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },
          {
            component: EvChart,
            path: "chart/:employeeid",
            title: "EvChart ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },
          // {
          //   component: EVDiscipline,
          //   path: "discipline/:employeeid",
          //   title: "EVDiscipline ",
          //   to: "/",
          //   type: "private",
          //   meta: { allowedRoles: [ROLES.PAYROLL] },
          // },
          {
            component: EvLeaveAlloacation,
            path: "leave-alloacation/:employeeid",
            title: "Leave Alloaction",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },
          {
            component: EVDocuments,
            path: "documents/:employeeid",
            title: "EVDocuments ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },
          {
            component: EVRecognition,
            path: "recognition/:employeeid",
            title: "EVRecognition ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },
          // {
          //   component: EVPerformance,
          //   path: "performance/:employeeid",
          //   title: "EVPerformance ",
          //   to: "/",
          //   type: "private",
          //   meta: { allowedRoles: [ROLES.PAYROLL] },
          // },
        ],
      },
      {
        component: Benefits,
        path: "benefits/:employeeid/:edit?",
        title: "Benefits ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.PAYROLL] },
      },
      {
        component: CertificatesInfo,
        path: "certificates-info/:employeeid/:edit?",
        title: "CertificatesInfo ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.PAYROLL] },
      },
      {
        component: JobDetails,
        path: "job-details/:employeeid/:edit?",
        title: "JobDetails ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.PAYROLL] },
      },
      {
        component: PersonalInfo,
        path: "personal-info/:employeeid/:edit?",
        title: "PersonalInfo ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.PAYROLL] },
      },
      {
        component: PayRollAccountLayout,
        path: "account",
        title: "Employee ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.PAYROLL] },
        children: [
          {
            component: EmployeePersonal,
            path: "personal-info/:employeeid",
            title: "EmployeePersonal ",
            to: "/",
            type: "private",
            index: true,
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },

          {
            component: EmployeeJobDetails,
            path: "job-details/:employeeid",
            title: "EmployeeJobDetails ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },
          {
            component: EVBenefits,
            path: "benefits/:employeeid",
            title: "Benefits ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },

          {
            component: EVLeaveHistory,
            path: "leave-history/:employeeid",
            title: "EVLeaveHistory ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },
          {
            component: EVCertificates,
            path: "certificates/:employeeid",
            title: "EVCertificates ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },
          {
            component: EvChart,
            path: "chart/:employeeid",
            title: "EvChart ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },
          {
            component: EVDiscipline,
            path: "discipline/:employeeid",
            title: "EVDiscipline ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },
          {
            component: EvLeaveAlloacation,
            path: "leave-alloacation/:employeeid",
            title: "Leave Alloaction",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },
          {
            component: EVRecognition,
            path: "recognition/:employeeid",
            title: "EVRecognition ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },
          {
            component: EVDocuments,
            path: "documents/:employeeid",
            title: "EVDocuments ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },
          {
            component: EVPerformance,
            path: "performance/:employeeid",
            title: "EVPerformance ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.PAYROLL] },
          },
        ],
      },
    ],
  },
  {
    component: UserLayout,
    path: "/user-management",
    title: "HUMAN_RESOURCE",
    to: "/",
    meta: { allowedRoles: [ROLES.EMPLOYEE] },
    type: "private",
    children: [
      {
        component: ManagerDashBoard,
        path: "dashboard",
        title: "User DashBoard",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.EMPLOYEE] },
      },
      {
        component: LeaveHistory,
        path: "leave/history/:employeeid?",
        title: "Leaves",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.EMPLOYEE] },
      },

      {
        component: Benefits,
        path: "benefits/:employeeid/:edit?",
        title: "Benefits ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.EMPLOYEE] },
      },
      {
        component: Documents,
        path: "documents",
        title: "Documents",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.EMPLOYEE] },
      },
      {
        component: DocumentHistory,
        path: "documents/history/:documentid",
        title: "Document History",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.EMPLOYEE] },
      },
      {
        component: Announcements,
        path: "announcements",
        title: "Announcements",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.EMPLOYEE] },
      },
      {
        component: AnnouncementDetails,
        path: "announcements/details/:id",
        title: "Announcements",
        to: "/",
        type: "private",
        index: true,
        meta: { allowedRoles: [ROLES.EMPLOYEE] },
      },
      // {
      //   component: Task,
      //   path: "tasks",
      //   title: "tasks",
      //   to: "/",
      //   type: "private",
      //   meta: { allowedRoles: [ROLES.EMPLOYEE] },
      //   index: true,
      // },
      // {
      //   component: TaskView,
      //   path: "tasks/details/:taskid",
      //   title: "Tasks Details",
      //   to: "/",
      //   type: "private",
      //   meta: { allowedRoles: [ROLES.EMPLOYEE] },
      //   index: true,
      // },
      {
        component: CertificatesInfo,
        path: "certificates-info/:employeeid/:edit?",
        title: "CertificatesInfo ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.EMPLOYEE] },
      },
      {
        component: JobDetails,
        path: "job-details/:employeeid/:edit?",
        title: "JobDetails ",
        to: "/",
        meta: { allowedRoles: [ROLES.EMPLOYEE] },
        type: "private",
      },
      {
        component: PersonalInfo,
        path: "personal-info/:employeeid/:edit?",
        title: "PersonalInfo ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.EMPLOYEE] },
      },
      {
        component: UserAccountLayout,
        path: "account",
        title: "Employee ",
        to: "/",
        type: "private",
        meta: { allowedRoles: [ROLES.EMPLOYEE] },
        children: [
          {
            component: EmployeePersonal,
            path: "personal-info/:employeeid",
            title: "EmployeePersonal ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.EMPLOYEE] },
            index: true,
          },

          {
            component: EmployeeJobDetails,
            path: "job-details/:employeeid",
            title: "EmployeeJobDetails ",
            to: "/",
            meta: { allowedRoles: [ROLES.EMPLOYEE] },
            type: "private",
          },
          {
            component: EVBenefits,
            path: "benefits/:employeeid",
            title: "Benefits ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.EMPLOYEE] },
          },

          {
            component: EVLeaveHistory,
            path: "leave-history/:employeeid",
            title: "EVLeaveHistory ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.EMPLOYEE] },
          },
          {
            component: EVCertificates,
            path: "certificates/:employeeid",
            title: "EVCertificates ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.EMPLOYEE] },
          },
          {
            component: EvChart,
            path: "chart/:employeeid",
            title: "EvChart ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.EMPLOYEE] },
          },
          {
            component: EVDiscipline,
            path: "discipline/:employeeid",
            title: "EVDiscipline ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.EMPLOYEE] },
          },
          {
            component: EvLeaveAlloacation,
            path: "leave-alloacation/:employeeid",
            title: "Leave Alloaction",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.EMPLOYEE] },
          },
          {
            component: EVDocuments,
            path: "documents/:employeeid",
            title: "EVDocuments ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.EMPLOYEE] },
          },
          {
            component: EVPerformance,
            path: "performance/:employeeid",
            meta: { allowedRoles: [ROLES.EMPLOYEE] },
            title: "EVPerformance ",
            to: "/",
            type: "private",
          },
          {
            component: EVRecognition,
            path: "recognition/:employeeid",
            title: "EVRecognition ",
            to: "/",
            type: "private",
            meta: { allowedRoles: [ROLES.EMPLOYEE] },
          },
        ],
      },
    ],
  },

  {
    component: RegisterOrganization,
    path: "/organization-admin/organization-profile",
    title: "register-organization",
    to: "/",
    type: "public",
    children: [],
  },
];
export default Routes;
